import { Box } from '@material-ui/core';
import { Modal } from 'antd';
import { images, polk_images } from 'assets/images';
import { Fragment, useEffect, useState } from 'react';
import { checkoutService } from 'services/lootbox/checkout';
import './explore.scss';
import { lbprofileAction } from "actions/lootbox/profile";
import { useHistory, useLocation } from 'react-router-dom';
import SlideCampaign from 'components/SlideCampaign';

import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';

import { Typography } from '@material-ui/core';



const defaultLe = ["Avatar","Bonus Pack NFTs","Epic","Giant","Medium","Standard"];

const Explore = () => {
    const { t, i18n } = useTranslation('common');
    const [showModal, setShowModal] = useState(true);
  const [userJwt, setUserJwt] = useState(null);
  const [levelUser, setLevelUser] = useState(null);
  const [level,setLevel]= useState(null)
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);
  const history = useHistory();
  useEffect(async() => {
    const level = await checkoutService.getUserLever();
    if(level.level_infor){
      setLevelUser(level.level_infor);
    }
    setShowModal(true);
  }, []);
  useEffect(async()=>{
    if(isLoggedIn){
      const level = await checkoutService.getUserLever();
      if(level.level_infor){
        setLevel(level)
        setLevelUser(level.level_infor);
      }
      setShowModal(true)
    }
  },[isLoggedIn])
  const handleClickConnect = async() => {
    if(isLoggedIn){
      lbprofileAction.showModalExplore(true)
      history.push("/launchpad")
    }else{
      await lbprofileAction.connect();
      history.push("/launchpad")
    }
  };
  let levelUser1 = {
    id: 0
  };
  const modalNotify = () => {
    return (
      <Modal
        width={600}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        className='modal-welcome'
      >
        <img src={ polk_images.welcome_banner } width='100%' />
        <div className='pt-3 px-5'>

          <h2>{t('welcome.welcome')}</h2>
          {levelUser && levelUser.id != 7 && levelUser.id !=0 && <Fragment>
            <div className='d-flex justify-content-center viplevel'>
              <h3>{t('welcome.youare')} <span>{t('welcome.level')} {levelUser.id}</span></h3>

            {/*<span style={{ color: '#6B718C', fontSize: 20, fontWeight: 600 }}>
              {t('welcome.youare')} <span style={{ color: '#F34F24' }}>{t('welcome.level')} {levelUser && levelUser.id != 7 ? levelUser.id : "0"}</span>

            </span>*/}
          </div>
          </Fragment>} 
          {levelUser && levelUser.id != 7 && levelUser.id !=0 && <div className='rule-user ml-2 mr-2 mt-5 mb-5'>
            <span
            className="item"
              style={{
                color: '#17204D',
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'center',
                display: 'table',
                margin: '0 auto',
              }}
            >
             {t('welcome.youcanbuy')}
            </span>
            {levelUser&& levelUser.product_can_buy && levelUser.product_can_buy.map((m,key) =>{
              if(m.max_amount > 0){
                return <span key={m.id} className="item" style={{ color: '#17204D', fontSize: 16, fontWeight: 600, display: 'table', margin: '0' }}>
              {m.max_amount} {m.name}
             </span>
              }
            })}
          </div>}
          <div className='d-flex justify-content-center pb-5'>
            <div
              onClick={()=>handleClickConnect()}
              style={{
                width: 360,
                height: 60,
                backgroundImage: 'linear-gradient(#c39a2f, #ebd6a1)',
                cursor: 'pointer',
                color: '#4d3700',
                fontWeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              role='button'
            >
              <span>{t('welcome.gotit')}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  return (
    <Box className='home'>
      {/* {modalNotify()} */}
      <Box className="d-none">
        <h1 className='col-12 d-flex justify-content-center'>SPORES x NETVRK</h1>
        <h3 className='col-12 d-flex justify-content-center'>{t('explorer.virtuallandsale')}</h3>
        <div className='col-md-6 col-xs-12 d-flex justify-content-center description'>{t('explorer.amet')}
        </div>
        <div className='col-12 d-flex justify-content-center'>
          <div className='button' role='button' onClick={()=>{handleClickConnect()}} >
            {/* {level && level.message == 0? <span>{t('explorer.participate')}</span>: <span>{t('explorer.participate')}</span>} */}
              SOLD OUT

          </div>
        </div>      
      </Box>
      <Box>
        <SlideCampaign type="HOME"/>
      </Box>
      <Box className="row d-flex flex-column home-product">
        <Box className="row layer-product-title">
          <h2 className='col-12 justify-content-center title-layer'>LAND TIERS</h2>
          <p style={{ color: 'white', fontSize: '14px'  }}>Our enchanted lands are divided into 5 tiers: Common, Epic, Rare, Legendary and Mythical. Each tier is presented with its very own magical gems and unique utilities.</p>
        </Box>      
        <Box className="row col-12 product-list">
          <Box className="product-item">
            <img src={ polk_images.product_common } />
          </Box>  
          <Box className="product-item">
            <img src={ polk_images.product_rare } />
          </Box>  
          <Box className="product-item">
            <img src={ polk_images.product_epic } />
          </Box>          
          <Box className="product-item">
            <img src={ polk_images.product_legendary } />
          </Box>          
          <Box className="product-item">
            <img src={ polk_images.product_mythical } />
          </Box>
        </Box>
        <Box className="roadmap"></Box>
      </Box>      
      <Box className="row d-flex flex-column home-group-product">
        <Box className="row layer-product-title">
          <h2 className='col-12 justify-content-center title-layer'>LAND TILES</h2>
          <p style={{ color: 'white', fontSize: '14px'  }}>PolkaFantasy’s land tiles are divided into 3 ranks. A standard tile only consists of a single unit of land. A S-rank tile is a combination of 2x2 units while the SS-rank tile offers a massive land of 3x3 units. Extraordinary opportunities to further develop buildings and expand farming space lie within the multi-unit tiles. A single unit NFT of multiple tiles will have bonus effects on many different utilities.</p>
        </Box>      
        <Box className="row col-12 product-list">
          <Box className="col-4 product-item">
            <img src={ polk_images.group_standard } />
          </Box>  
          <Box className="col-4 product-item">
            <img src={ polk_images.group_srank } />
          </Box>  
          <Box className="col-4 product-item">
            <img src={ polk_images.group_ssrank } />
          </Box>          
        </Box>
        <Box className="roadmap"></Box>
      </Box>      
      <Box className="row d-flex flex-column home-inventory-product">
        <Box className="row layer-product-title">
          <h2 className='col-12 justify-content-center title-layer'>INVENTORY</h2>
        </Box>      
        <Box className="row col-12 product-list">
          <Box className="col-4 product-item">
            <img src={ polk_images.inventory_standard } />
          </Box>  
          <Box className="col-4 product-item">
            <img src={ polk_images.inventory_srank } />
          </Box>  
          <Box className="col-4 product-item">
            <img src={ polk_images.inventory_ssrank } />
          </Box>          
        </Box>
        <Box className="roadmap"></Box>
      </Box>
    </Box>
  );
};

export default Explore;
