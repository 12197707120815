import './style.scss'
import { Fragment, useState, useEffect } from "react";
import {useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Thankyou from '../Thankyou/index';
import { checkoutService } from 'services/lootbox/checkout';
import moment from 'moment';
import { notification, message } from 'antd';
import { Modal } from 'antd';
import { CircularProgress } from '@material-ui/core';
import { cartAction } from 'actions/cart';
import { useTranslation } from 'react-i18next';
import { ADDRESS_RECEIVER } from 'env';
import copppy from "assets/images/copppy.png"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import isEmail from 'validator/lib/isEmail';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles({
    table: {
    },
});
function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0),
    createData('Ice cream sandwich', 237, 9.0),
    createData('Eclair', 262, 16.0),
];
function Checkout(props) {
    const { t, i18n } = useTranslation('common');
    const { id,type } = useParams();
    const { tranHash, typeSubmit, setIsShowCheckout } = props;
    const { trans_hash } = useSelector(({ common }) => common);
    const classes = useStyles();
    const [thankModal, setThankModal] = useState(false);
    const [durationDiff, setDurationDiff] = useState(null);
    const [statusSubmit, setStatusSubmit] = useState(true);
    const [listOrderDetail, setListOrderDetail] = useState(false);
    const [totalItem, setTotalItem] = useState(0);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [jwtUser,setJwtUser] =  useState(null)
    const [orders,setOrder] = useState(null)
    const [tranHashValue,setTranHash] = useState(null);
    const { language } = useSelector(({ coreui }) => coreui);
    const history = useHistory();
    useEffect(() => {
        if (trans_hash != null && trans_hash != "") {
            setLoading(true)
            setTranHash(trans_hash)
        }else{
            setLoading(false)
        }
    }, [trans_hash])
    useEffect(() => {
        if (orders) {
            if(orders.status != 1){
                history.push("/cart")
                return;
            }
                const jwt = localStorage.getItem('jwtJson')
                if (jwt) {
                const jwtJson = JSON.parse(jwt);
                localStorage.setItem(jwtJson.wallet_address+ "_time", moment(orders.order_time).unix())
                localStorage.setItem(jwtJson.wallet_address +"_order",id)
                }
            countDownTime();
        }
    }, [orders]);
    function countDownTime() {
        const eventTime = moment(orders.order_time).unix();
        const currentTime = moment().unix();
        const tem = currentTime - eventTime;
        let diffTime = 900000 - tem*1000;
        if (diffTime >= 0) {
            let ress = moment.duration(diffTime).asMilliseconds();
            const count = setInterval(function (id) {
                ress = ress - 1000;
                if (ress >= 0) {
                    setDurationDiff(ress);
                } else {
                    setDurationDiff(0);
                    setStatusSubmit(false);
                    localStorage.removeItem("card_checkout_land")
                    cartAction.updateOrderCount();
                    cartAction.setTranshash("")
                    localStorage.removeItem(jwtUser.wallet_address + "_order");
                    localStorage.removeItem(jwtUser.wallet_address + "_time");
                    history.push("/cart")
                    clearInterval(count);
                }
            }, 1000);
        }
    }

    useEffect(async () => {
        if (id) {
            const res = await checkoutService.historyOrder(id)
            setListOrderDetail(res.data.orderdetails)
            setOrder(res.data.orders)
        }
    }, [id])
    useEffect(() => {
        if (listOrderDetail) {
            let total = 0;
            listOrderDetail.map(m => {
                total = total + m.quantity;
            })
            setTotalItem(total)
        }
    }, [listOrderDetail])
    const onChangeEmail=(e)=>{
        setEmail(e.target.value);
    }
    useEffect(()=>{
        const jwt = localStorage.getItem("jwtJson");
        if(jwt != null && jwt != undefined){
           const jwtJson = JSON.parse(jwt);
            setJwtUser(jwtJson)
        }
    },[])
    const setTranHashChange=(e)=>{
        setTranHash(e.target.value)
    }
    const handleSubmit = async () => {
        if (!statusSubmit) {
            notification.error({ message: 'Time submit expired' });
            return;
        }
        if(jwtUser && jwtUser.level_id == 7){
            if(!isEmail(email)){
                notification.error({ message: 'Email in valid' });
                return
            }
        }
        if(!tranHashValue || tranHashValue.length < 64){
            notification.error({ message: 'Transaction id in valid' });
            return
        }
        const body = {
            "email": email,
            "order_id": parseInt(id),
            "transaction_hash": tranHashValue
        }
        if (typeSubmit == "land") {
            const resSubmit = await checkoutService.submitLand(body)
            localStorage.removeItem("card_checkout_land")
        } else if (typeSubmit == "avatar") {
            const resSubmit = await checkoutService.submitavatar(body)
            localStorage.removeItem("card_checkout_avatar")
        }
        localStorage.removeItem(jwtUser.wallet_address + "_order");
        localStorage.removeItem(jwtUser.wallet_address + "_time");
        cartAction.setTranshash("")
        cartAction.updateOrderCount();
        setThankModal(true);
    }
    const handleCancle = () => {
        setThankModal(false)
        setIsShowCheckout(false)
        history.push("/launchpad")
    }
    return (
        <Fragment >
            {!thankModal &&
                <Modal
                    visible={true}
                    footer={null}
                    closable={false}
                    bodyStyle={{ background: 'transparent', padding: 0 }}
                >
                    <div className='wrap-checkout'>
                        <div className='header'>
                            {t('checkout.checkout')}
                        </div>
                        <div className='title-countdown'>
                            <div className='title'>
                                {t('checkout.order_expire')}
                            </div>
                            <div className='countdown'>
                                <div className='min'>
                                    <div className='box'> {moment.duration(durationDiff).minutes()}</div>
                                    {t('checkout.minutes')}
                                </div>
                                <div className='dot'>:</div>
                                <div className='sec'>
                                    <div className='box'> {moment.duration(durationDiff).seconds()} </div>
                                    {t('checkout.seconds')}
                                </div>
                            </div>
                        </div>
                        <div className='order-detail'>
                            <div className="order-detail-header">
                                {t('checkout.order_details')}
                            </div>
                            <TableContainer style={{ borderRadius: '10px' }}>
                                <Table className={classes.table} size="small" aria-label="a dense table">

                                    <TableBody style={{ backgroundColor: '#4E4462' }}>
                                        {listOrderDetail && listOrderDetail.map((row) => (
                                            <TableRow key="" style={{ borderBottom: "none" }}>
                                                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                                                    {language && language!="jp"?row.product_name:row.name_simplified_chinese}
                                                </TableCell>
                                                <TableCell style={{ borderBottom: "none" }} align="right">{row.quantity}</TableCell>
                                                <TableCell style={{ borderBottom: "none" }} align="right"><NumberFormat value={row.price} displayType={'text'} thousandSeparator={true} /> {row.currency}</TableCell>
                                            </TableRow>
                                        ))}
                                        <TableRow style={{ borderBottom: "none" }} className="check7" >
                                            <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                                                {t('checkout.total')}
                                            </TableCell>
                                            <TableCell style={{ borderBottom: "none" }} align="right">{totalItem}</TableCell>
                                            <TableCell style={{ borderBottom: "none", color: '#FFC400' }} align="right">{orders && <NumberFormat value={orders.total_amount} displayType={'text'} thousandSeparator={true} />} {listOrderDetail&& listOrderDetail.length >0 && listOrderDetail[0].currency}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className='desc'>
                            <div className="note-box">
                                <ul>
                                    <li>
                                        <span>
                                            {t('checkout.select_process_ethereum')}
                                        </span>
                                        <br />
                                        <span className="primary-key">
                                            {ADDRESS_RECEIVER} {" "}
                                            <CopyToClipboard  text={ADDRESS_RECEIVER}>
                                                <img style={{cursor:"pointer"}} src={copppy} />
                                            </CopyToClipboard>
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            {t('checkout.complete_order_within_time')}
                                        </span>
                                    </li>
                                    <li>
                                        <span>
                                            {t('checkout.upon_sucess_payment')}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        
                        {jwtUser&& jwtUser.level_id == 7 &&<div className='input-id'>
                            <div className="order-detail-header">
                                {/* {t('checkout.transaction_id')} {"   "} */}
                                Email
                            </div>
                            <input className="tran_id" type="text" defaultValue={email} onChange={(e)=> onChangeEmail(e)} />
                        </div>}

                        <div className='input-id'>
                            <div className="order-detail-header">
                                {t('checkout.transaction_id')} {"   "}
                                {!loading && <CircularProgress color="secondary" size={10} />}
                            </div>
                            <input className="tran_id" type="text" value={tranHashValue} onChange={(e)=>setTranHashChange(e)} defaultValue={tranHash} />
                        </div>

                        <div className='btn-submit'>
                            <div className='col-btn' >
                                <div className='btn-checkout' onClick={handleSubmit}>
                                    {t('checkout.submit')}
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
            {
                thankModal && <Thankyou setThankModal={handleCancle} />
            }

        </Fragment>
    )
}
export default Checkout
