import React, {useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppMenu } from 'containers';
import { NetworkBar, SearchBar, ColorButton } from 'components';
import { Paper, Hidden, IconButton, Drawer, Button } from '@material-ui/core';
import { List, ListItem } from '@material-ui/core';
import { Dropdown } from 'antd';
import { profileAction } from 'actions/profile';
import { privateRoute } from 'routes';
import { UserAvatar } from 'views/Profile/components';
import { lbprofileAction } from "actions/lootbox/profile";
import { useTranslation } from 'react-i18next';
import { StarFilled } from '@ant-design/icons';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { polk_images } from '../assets/images';

import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import LanguageBar from 'components/LanguageBar'

// import io from "socket.io-client";

// const host = "ws://netvr.xyz:8002/notify";
// import { w3cwebsocket as W3CWebSocket } from "websocket";

// const client = new W3CWebSocket('ws://netvr.xyz:8002/notify',null,{
//   headers: {
//     Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzY0NzU2MTQsImlkIjoxNiwibGV2ZWxfaWQiOjcsInB1YmxpY19rZXkiOiIwNDk2MmFmYWQ4MDBiMzU1NmQ3MTJkYzlhOTVlNmI2MGRlYWY1OTdkMzZlOTliZWVmZmM0ZjY0MTI1YTAzNjYyMDllYmVlYjk3OGYxODEzM2E3NWI4NDRhYWYyMzIxMGY3Zjk0ZmFhNThmZGQ0ZWFhYzVjOWE5NmQxMTNmYzBhYjkyIiwid2FsbGV0X2FkZHJlc3MiOiIweDE1NmZhZTRhMzNmZmUzOGUxYWQwOTdlOGM3NGJhMDE5MmZiMTBhYTAifQ.5edp_crBsQfvJGA7oLoTX8OSO38God77O223OHK7QNY"
//   },
// });



const Header = () => {
  const { t, i18n } = useTranslation('common');

  const { isLoggedIn, mintable = false } = useSelector(({ profileLootbox }) => profileLootbox);

  const [isOpenMenu, setIsOpenMenu] = React.useState(false);
  const handleOpenMenu = (open) => (event) => setIsOpenMenu(open);
  const [userJwt, setUserJwt] = useState(null);
  const [addressSub, setAddress] = useState(null);

  const socketRef = useRef();

  const handleClickConnect = () => {
    lbprofileAction.connect();
  };

  // useEffect(() => {
    // socketRef.current = socketIOClient.connect(host, {
    //   extraHeaders: {
    //     Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzY0NzU2MTQsImlkIjoxNiwibGV2ZWxfaWQiOjcsInB1YmxpY19rZXkiOiIwNDk2MmFmYWQ4MDBiMzU1NmQ3MTJkYzlhOTVlNmI2MGRlYWY1OTdkMzZlOTliZWVmZmM0ZjY0MTI1YTAzNjYyMDllYmVlYjk3OGYxODEzM2E3NWI4NDRhYWYyMzIxMGY3Zjk0ZmFhNThmZGQ0ZWFhYzVjOWE5NmQxMTNmYzBhYjkyIiwid2FsbGV0X2FkZHJlc3MiOiIweDE1NmZhZTRhMzNmZmUzOGUxYWQwOTdlOGM3NGJhMDE5MmZiMTBhYTAifQ.5edp_crBsQfvJGA7oLoTX8OSO38God77O223OHK7QNY"
    //   }
    // })
    // const socket =io(host, {
    //   path: '/notify',
    //   extraHeaders: {
    //     Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MzY0NzU2MTQsImlkIjoxNiwibGV2ZWxfaWQiOjcsInB1YmxpY19rZXkiOiIwNDk2MmFmYWQ4MDBiMzU1NmQ3MTJkYzlhOTVlNmI2MGRlYWY1OTdkMzZlOTliZWVmZmM0ZjY0MTI1YTAzNjYyMDllYmVlYjk3OGYxODEzM2E3NWI4NDRhYWYyMzIxMGY3Zjk0ZmFhNThmZGQ0ZWFhYzVjOWE5NmQxMTNmYzBhYjkyIiwid2FsbGV0X2FkZHJlc3MiOiIweDE1NmZhZTRhMzNmZmUzOGUxYWQwOTdlOGM3NGJhMDE5MmZiMTBhYTAifQ.5edp_crBsQfvJGA7oLoTX8OSO38God77O223OHK7QNY"
    //   }
    // });
    // socket.on('message', message => {
    //   console.log(message);
    // });
  //   client.onopen = () => {
  //     console.log('WebSocket Client Connected');
  //   };
  //   client.onmessage = (message) => {
  //     console.log(message);
  //   };
  // }, []);

  const handleValue=()=>{
    if(userJwt){
      const str = userJwt.wallet_address
      return userJwt.wallet_address.substr(0,3) + userJwt.wallet_address.substr(str.length - 3, str.length-1)
    }
  }

  useEffect(()=>{
    const jwtJson = localStorage.getItem("jwtJson");
    if(jwtJson != null && jwtJson != undefined){
      const json = JSON.parse(jwtJson)
      setUserJwt(json)
      const str = json.wallet_address
      const add =str.substr(0,3) +"..."+ str.substr(str.length - 3, str.length-1)
      setAddress(add);
    }
  },[isLoggedIn])

  const handleClickDisconnect = () => {
    lbprofileAction.signOut();
  };

  return (
    <Paper square className='App-Header' color='primary'>
      <Hidden xsDown>
        <Link to={privateRoute.home.path} className='mr-20'>
          <img src={polk_images.logo_main} height={40} />
        </Link>
      </Hidden>
      <NetworkBar />

      <div style={{ flex: 2 }} />


      
      {isLoggedIn ? (
        <>
         {userJwt && userJwt.level_id != 7 && userJwt.level_id != 0 && <Button className='header-level' variant="contained" color="primary" style={{ minWidth: 140, backgroundColor: '#806AF7', color: 'white', border: '1px solid #806AF7', borderRadius: '4px', marginRight: '5px' }} >VIP LEVEL {userJwt && userJwt.level_id }</Button>} 
          
          <Dropdown
            trigger='click'
            placement='bottomRight'
            getPopupContainer={(event) => event.parentNode}
            overlay={
              <div>
                <List disablePadding component={Paper} style={{ marginTop: 6, minWidth: 180 }}>
                  <CopyToClipboard text={userJwt && userJwt.wallet_address }>
                  <ListItem button component={Link}>
                    {t('header.copyaddress')}
                  </ListItem>
                  </CopyToClipboard>
                  <ListItem button divider component="a" href="/history">
                    {t('header.history')}
                  </ListItem>                  
                  <ListItem button onClick={handleClickDisconnect}>
                    {t('header.disconnect')}
                  </ListItem>
                </List>
              </div>
            }
          >
            <Button variant="contained" color="primary" endIcon={<ArrowDropDownOutlinedIcon />} style={{ minWidth: 140, backgroundColor: '#40394F', color: 'white', border: '1px solid #5E6484', borderRadius: '4px', marginRight: '5px' }}>
              {addressSub}
            </Button>
          </Dropdown>
          {mintable && (
            <Link to={privateRoute.createType.path}>
              <ColorButton startIcon={<CloudUploadOutlinedIcon />}>Create</ColorButton>
            </Link>
          )}
        </>
      ) : (
        <ColorButton onClick={handleClickConnect} style={{marginRight:"10px"}}>Connect wallet</ColorButton>
      )}
      <Hidden mdUp>
        <IconButton onClick={handleOpenMenu(true)}>
          <MenuOutlinedIcon />
        </IconButton>

        <Drawer anchor='top' open={isOpenMenu} onClose={handleOpenMenu(false)}>
          <List style={{ width: '100%' }}>
            <AppMenu onClickMenu={handleOpenMenu(false)} />
          </List>
        </Drawer>
      </Hidden>      
      <AppMenu onClickMenu={handleOpenMenu(false)} />
      <LanguageBar init />
    </Paper>
  );
};

export default Header;
