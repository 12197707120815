import image_artist_1 from './artist/image-artist-1.jpg';
import image_artist_2 from './artist/image-artist-2.jpg';
import image_artist_3 from './artist/image-artist-3.jpg';
import image_artist_4 from './artist/image-artist-4.jpg';
import image_artist_5 from './artist/image-artist-5.jpg';
import image_artist_6 from './artist/image-artist-6.jpg';
import plane from './plane.png';
import moto from './moto.png';
import roadster from './roadster.png';
import bullet from './bullet.png';
import car from './car.png';
import bg_home from '../images/bg-home.png';
import iconRight from './arrow-right.png';
import bg_countdown from './bg-countdown.png';
import virtual_item from './virtual-item.png';
import ic_dollar from './ic_dollar.png';
import ic_dollar_new from './ic_dollar_new.png';
import ic_plain from './ic_plain.png';
import bg_welcome from './bg-welcome.png';
import ic_cart from './ic_cart.png';
import ic_cart1 from './ic_cart1.png';
import ic_back from './ic_back.png';
import ic_addcart from './add_cart.png';
import ic_add_to_cart from './add-to-cart.png';
import ic_warning from './warning.png';
import btn_addcart from './btn-addcart.png';
import btn_buy from './btn-buy.png';
import btn_buyInactive from './btn-buyInactive.png';
import s_1 from './s-1.png';
import s_2 from './s-2.png';
import s_3 from './s-3.png';
import s_4 from './s-4.png';
import s_5 from './s-5.png';
import s_6 from './s-6.png';
import s_7 from './s-7.png';
import s_8 from './s-8.png';

//polkfantasy
import polk_logo_white_orange from './polk/polk_logo_white_orange.png';
import polk_logo_white from './polk/polk_logo_white.png';
import polk_logo_half from './polk/polk_logo_half.png';
import polk_button_diamond_line from './polk/polk_button_diamond_line.png';
import polk_product_common from './polk/polk_p_common.png';
import polk_product_rare from './polk/polk_p_rare.png';
import polk_product_epic from './polk/polk_p_epic.png';
import polk_p_rare_mini from './polk/polk_p_rare_mini.png';
import polk_product_legendary from './polk/polk_p_legendary.png';
import polk_product_mythical from './polk/polk_p_mythical.png';
import polk_group_srank from './polk/polk_g_srank.png';
import polk_group_ssrank from './polk/polk_g_ssrank.png';
import polk_group_standard from './polk/polk_g_standard.png';
import polk_inventory_standard from './polk/polk_inventory_standard.png';
import polk_inventory_srank from './polk/polk_inventory_srank.png';
import polk_inventory_ssrank from './polk/polk_inventory_ssrank.png';
import polk_cart from './polk/cart.png';
import polk_social_1 from './polk/polk_social_1.png';
import polk_social_2 from './polk/polk_social_2.png';
import polk_social_3 from './polk/polk_social_3.png';
import polk_social_4 from './polk/polk_social_4.png';
import polk_social_5 from './polk/polk_social_5.png';
import polk_social_6 from './polk/polk_social_6.png';
import polk_social_7 from './polk/polk_social_7.png';
import polk_social_8 from './polk/polk_social_8.png';
import polk_welcome_banner from './polk/polk_welcome_banner.jpg';
import polk_liveaution_product from './polk/polk_liveauction_product.jpg';
import polk_usdt from './polk/polk_usdt.png';
import polk_usdc from './polk/polk_usdc.png';
import polk_logo_and_fantasy_white from './polk/polk_logo_and_fantasy_white.png';
import polk_slide_bg from './polk/polk_slide_bg.jpg';
import polk_button_rare from './polk/polk_button_rare.png';
import polk_button_rare_1 from './polk/polk_button_rare_2.png';
import polk_button_common from './polk/polk_button_common.png';
import polk_button_common_1 from './polk/polk_button_common_2.png';
import polk_button_epic from './polk/polk_button_epic.png';
import polk_button_epic_1 from './polk/polk_button_epic_2.png';
import polk_button_legendary from './polk/polk_button_legendary.png';
import polk_button_legendary_1 from './polk/polk_button_legendary_2.png';
import polk_button_mythical from './polk/polk_button_mythical.png';
import polk_button_mythical_1 from './polk/polk_button_mythical_2.png';

const polk_images = {
  logo_main: polk_logo_white_orange,
  logo_footer: polk_logo_white_orange,
  logo_campaign: polk_logo_and_fantasy_white,
  polk_p_rare_mini: polk_p_rare_mini,
  logo_footer_half: polk_logo_half,
  polk_button_diamond_line: polk_button_diamond_line,
  product_common: polk_product_common,
  product_rare: polk_product_rare,
  product_epic: polk_product_epic,
  product_legendary: polk_product_legendary,
  product_mythical: polk_product_mythical,
  group_standard: polk_group_standard,
  group_srank: polk_group_srank,
  group_ssrank: polk_group_ssrank,
  inventory_ssrank: polk_inventory_ssrank,
  inventory_srank: polk_inventory_srank,
  inventory_standard: polk_inventory_standard,
  cart: polk_cart,
  s_1: polk_social_1,
  s_2: polk_social_2,
  s_3: polk_social_3,
  s_4: polk_social_4,
  s_5: polk_social_5,
  s_6: polk_social_6,
  s_7: polk_social_7,
  s_8: polk_social_8,
  welcome_banner: polk_welcome_banner,
  liveaution_product: polk_liveaution_product,
  usdt: polk_usdt,
  usdc: polk_usdc,
  slide_bg: polk_slide_bg,
  button_common: polk_button_common,
  button_epic: polk_button_epic,
  button_legendary: polk_button_legendary,
  button_mythical: polk_button_mythical,
  button_rare: polk_button_rare,  
  button_common_1: polk_button_common_1,
  button_epic_1: polk_button_epic_1,
  button_legendary_1: polk_button_legendary_1,
  button_mythical_1: polk_button_mythical_1,
  button_rare_1: polk_button_rare_1,
};


const artists = [image_artist_1, image_artist_2, image_artist_3, image_artist_4, image_artist_5, image_artist_6];

const images = {
  bg_home: bg_home,
  iconRight: iconRight,
  bg_countdown: bg_countdown,
  virtual_item: virtual_item,
  ic_dollar: ic_dollar,
  ic_dollar_new: ic_dollar_new,
  ic_plain: ic_plain,
  bg_welcome: bg_welcome,
  ic_cart: ic_cart,
  ic_cart1: ic_cart1,
  ic_back: ic_back,
  ic_addcart: ic_addcart,
  btn_addcart: btn_addcart,
  btn_buy: btn_buy,
  btn_buyInactive:btn_buyInactive,
  ic_warning: ic_warning,
  car:car,
  bullet,
  plane,
  roadster,
  moto,
  s_1,
  s_2,
  s_3,
  s_4,
  s_5,
  s_6,
  s_7,
  s_8
};



export { images, artists, polk_images };
/*
export const images = {
  banner_introduce: './banner-introduce.png',
  rarity: './rarity.png',
  rarity1: './rarity.png',
  rarity2: './rarity.png',
  rarity3: './rarity.png',
  rarity4: './rarity.png',
  lootbox: './lootbox.png',
  line: './line.png',
  texttime: './texttime.png',
  item_drop1: './item-drop1.png',
  item_drop: './item-drop.png',
  ic_ratiry: './ic-rarity.png',
  thumb_art: './art_thumb.png',
};
};

export { images, artists };
*/
