/* eslint-disable jsx-a11y/alt-text */
import { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import CountDown from 'components/CountDown';
import { Card, List } from 'antd';
import { images, polk_images } from 'assets/images';
import ItemLaunchpad from './ItemLaunchpad';
import { useTranslation } from 'react-i18next';
import { productService } from 'services/lootbox/product';
import { checkoutService } from 'services/lootbox/checkout';
import trans from 'assets/images/trans.png';
import { Modal } from 'antd';
import LiveAuction from 'components/LiveAuction';
import netvrk_1 from '../../assets/images/netvrk-v2-1.jpg';
import netvrk_2 from '../../assets/images/netvrk-v2-2.jpg';
import netvrk_3 from '../../assets/images/netvrk-v2-3.jpg';
import netvrk_4 from '../../assets/images/netvrk-v2-4.jpg';
import netvrk_5 from '../../assets/images/netvrk-v2-5.jpg';
import netvrk_6 from '../../assets/images/netvrk-v2-6.jpg';
import netvrk_7 from '../../assets/images/netvrk-v2-7.jpg';
import netvrk_8 from '../../assets/images/netvrk-v2-8.jpg';
import netvrk_9 from '../../assets/images/netvrk-v2-9.jpg';
import netvrk_10 from '../../assets/images/netvrk-v2-10.jpg';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { lbprofileAction } from "actions/lootbox/profile";
import SlideCampaign from 'components/SlideCampaign';

export default function Launchpad() {
  const [itemCampain] = useState({});
  const [products, setProducts] = useState([]);
  const [landItem, setLandItem] = useState([]);
  const [avatarItem, setAvatarItem] = useState([]);
  const { t, i18n } = useTranslation('common');
  const [useLevel, setUserLevel] = useState(null);
  const history = useHistory();
  const { showModalExplore } = useSelector(({ profileLootbox }) => profileLootbox);
  const [levelUser, setLevelUser] = useState(null);
  const [rankss,setRankss] = useState([]);
  const [ranks,setRanks]= useState([])
  const [rank,setRank]= useState([])

  useEffect(async () => {
    const res = await checkoutService.getUserLever();
    setUserLevel(res);
    setLevelUser(res.level_infor);
  }, []);
  function swap(arr, from, to) {
    arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
  }

  const handleCheckType=(name)=>{
    const type = name.slice(-2);
    const type1 = name.slice(-1);
    if(type == "SS"){
      return 1
    }
    if(type1 == "S"){
      return 2;
    }
    return 0
  }
  const modalNotify = () => {
    return (
      <Modal
        width={600}
        visible={showModalExplore}
        onCancel={() => lbprofileAction.showModalExplore(false)}
        footer={null}
        closable={false}
        bodyStyle={{ padding: 0, margin: 0 }}
        className='modal-welcome'
      >
        <img src={polk_images.welcome_banner} width='100%' />
        <div className='pt-3 px-5'>


          <h2 style={{ textAlign: 'center', fontWeight: 600 }}>{t('welcome.welcome')}</h2>
          {levelUser && levelUser.id != 7 && <Fragment>
            <div className='d-flex justify-content-center'>
              <span style={{ color: '#6B718C', fontSize: 20, fontWeight: 600 }}>
                {t('welcome.youare')} <span style={{ color: '#F34F24' }}>{t('welcome.level')} {levelUser && levelUser.id}</span>

              </span>
            </div></Fragment>}
          {levelUser && levelUser.id != 7 && <div style={{ backgroundColor: '#F4F4F7' }} className='rule-user ml-2 mr-2 mt-5 mb-5'>
            <span
              className="item"
              style={{
                color: '#17204D',
                fontSize: 16,
                fontWeight: 600,
                textAlign: 'center',
                display: 'table',
                margin: '0 auto',
              }}
            >
              {t('welcome.youcanbuy')}
            </span>
            {levelUser && levelUser.product_can_buy && levelUser.product_can_buy.map((m, key) => {
             if(m.max_amount>0){
              return <span key={m.id} className="item" style={{ color: '#17204D', fontSize: 16, fontWeight: 600, display: 'table', margin: '0' }}>
              {m.max_amount} {m.name}
            </span>
             }
            })}
          </div>}
          <div className='d-flex justify-content-center pb-5'>
            <div
              style={{
                width: 360,
                height: 60,
                backgroundImage: 'linear-gradient(#c39a2f, #ebd6a1)',
                cursor: 'pointer',
                color: '#4d3700',
                fontWeight: 700,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              role='button'
              onClick={() => { lbprofileAction.showModalExplore(false) }}
            >
              <span>{t('welcome.gotit')}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  };
  useEffect(async () => {
    const res = await productService.product();
    const productres = [...res.data].reverse();
    // const land = productres.filter((f) => f.product.product_type == 1);
    // const avatar = productres.filter((f) => f.product.product_type != 1);
    // if (land != null && land.length > 0) {
    //   const sort = land.sort((a, b) => { return (b.product.price - a.product.price) })
    //   setLandItem(sort);
    // }
    // if (avatar != null && avatar.length > 0) {
    //   swap(avatar, 1, 2);
    //   swap(avatar, 0, 1);
    //   const sort = avatar.sort((a, b) => { return (b.product.price - a.product.price) })
    //   setAvatarItem([...sort]);
    // }
    // setProducts(productres);
    const rankss = productres.filter(f => handleCheckType(f.product.name) == 1);
    const ranks = productres.filter(f => handleCheckType(f.product.name) == 2);
    const rank = productres.filter(f => handleCheckType(f.product.name) == 0);
    setRankss(rankss.sort((a, b) => { return (a.product.price - b.product.price) }))
    setRanks(ranks.sort((a, b) => { return (a.product.price - b.product.price) }))
    setRank(rank.sort((a, b) => { return (a.product.price - b.product.price) }))
  }, []);
  let pageStyle = {
    width: '100%', position: 'relative', float: 'left'
  };
  return (
    <Box className='launchpad-page'>
      <div style={{ ...pageStyle }}>
        {modalNotify()}
        <div className='campain-page launchpad-container'>
          {/* <div className='banner'>
            <CountDown data={itemCampain} />
          </div> */}
          <Box>
            <SlideCampaign />
          </Box>
          <Box className="row live-auction">
            <h2 className="col-12 box-header">{t('liveauction.header')}</h2>
            <Box className="col-12 d-flex flex-row live-auction-content">
                <LiveAuction />
            </Box>
            <Box className="roadmap"></Box> 
          </Box>
          <Box className="row product-group">
            <div className='product-list col-12'>
              <h2 className="box-header">{t('launchpad.ssrank')}</h2>
              <div className='list-item'>
                <List
                  grid={{ gutter: [50, 4], column: 4, xs: 2, sm: 2, md: 4, lg: 4 }}
                  dataSource={rankss}
                  renderItem={(item) => (
                    <List.Item>
                      <ItemLaunchpad useLevel={useLevel} products={item} title='virtual' />
                    </List.Item>
                  )}
                />
              </div>
            </div>
            <div className='product-list col-12'>
              <div>
                <h2 className="box-header">{t('launchpad.srank')}</h2>
                <div className='list-item'>
                  <List
                    grid={{ gutter: [50, 8], column: 4, xs: 2, sm: 2, md: 4, lg: 4 }}
                    dataSource={ranks}
                   
                    renderItem={(item) => (
                      <List.Item>
                        <ItemLaunchpad useLevel={useLevel} products={item} title='virtual' />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className='product-list col-12'>
              <div>
                <h2 className="box-header">{t('launchpad.standard')}</h2>
                <div className='list-item'>
                  <List
                    grid={{ gutter: [20, 5], column: 5, xs: 2, sm: 2, md: 5, lg: 5 }}
                    dataSource={rank}
                   
                    renderItem={(item) => (
                      <List.Item>
                        <ItemLaunchpad useLevel={useLevel} products={item} title='virtual' />
                      </List.Item>
                    )}
                  />
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
    </Box>

  );
}
