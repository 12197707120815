/* eslint-disable jsx-a11y/alt-text */

import CountDown from 'components/CountDown';
import { Card, Spin, List, Row, Col, Modal, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { polk_images,images } from 'assets/images';
import { Progress } from 'reactstrap';
import { API_URI_LOOTBOX, URL_LAND } from 'env';
import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { notification, message } from 'antd';

import { useTranslation } from 'react-i18next';
import './launchpad.scss'
import NumberFormat from 'react-number-format';

import { checkoutService } from 'services/lootbox/checkout';
import { set } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { cartAction } from 'actions/cart';
import { makeStyles } from '@material-ui/core/styles';
import { productService } from 'services/lootbox/product';
import {MOCK_DATA} from "./Product.js"
export default function ItemLaunchpad(props) {
  const classes = useStyles();
  const { products, useLevel, title } = props;
  const {count} = useSelector(({common}) => common)
  const [showModal, setShowModal] = useState(false);

  const { t, i18n } = useTranslation('common');
  const [countOrd,setCountOrder] = useState(0)

  const history = useHistory();
  const [disable, setDisable] = useState(false);
  const { language } = useSelector(({ coreui }) => coreui);
  const [exceedModal,setExceedModal] = useState(false);
  
  

  const handleAddToCard = async() => {
    if (disable) {
      return;
    }
    const check =await handleCheckAmount(products.product.id)
    if(!check){
      notification.success({ message: 'item not exist' });
      return
    }
    notification.success({ message: 'add item success' });
    handleCardLand();
    handleCardAvartar();
    setShowModal(false);
  };
  const handleBuyNow =async () => {
    if (disable) {
      return;
    }
    const check =await handleCheckAmount(products.product.id)
    if(!check){
      notification.success({ message: 'item not exist' });
      return
    }
    handleCardLand();
    handleCardAvartar();
    history.push('/cart');
  };

  useEffect(() => {
    console.log("mocs",MOCK_DATA)
    setDisable(false)
    if(!useLevel){
      setDisable(true);
      return;
    }
    if (products) {
      if (useLevel.message != '0' && useLevel.message != "double") {
        setDisable(true);
        return;
      }
      if (products.remain_quantity == 0) {
        setDisable(true);
        return;
      }
      if (useLevel.data == 'unlimited') {
        return;
      }
      if (products.product.product_type == 1) {
        if (useLevel.data[products.product.name] == undefined || useLevel.data[products.product.name] == 0) {
          setDisable(true);
          setExceedModal(true);
        }
      }
      if (products.product.product_type == 2) {
        if (useLevel.data.Avatar == undefined || useLevel.data.Avatar == 0) {
          setDisable(true);
          setExceedModal(true);
        }
      }
      if (products.product.product_type == 3) {
        if (useLevel.data['Bonus Pack NFTs'] == undefined || useLevel.data['Bonus Pack NFTs'] == 0) {
          setDisable(true);
          setExceedModal(true);
        }
      }
    }
  }, [useLevel]);

  useEffect(()=>{
    handlecountorder()
  },[count])

  const handleCheckAmount =async (id)=>{
    const pro = await productService.productDetail(id);
    if(pro.data && pro.data.remain_quantity > 0){
      return true
    }
    return false
  }

  const handleCardLand = () => {
    const listCard = localStorage.getItem('card_checkout_land');
    let listCardTemp = [];
    if (listCard != null && listCard != undefined) {
      listCardTemp = JSON.parse(listCard);
      const filter = listCardTemp.filter((f) => f.product.id == products.product.id);
      if ((filter == null || filter.length == 0) && products.product.product_type == 1) {
        listCardTemp.push(products);
      }
    } else {
      if (products.product.product_type == 1) {
        listCardTemp.push(products);
      }
    }
    cartAction.updateOrderCount();
    localStorage.setItem('card_checkout_land', JSON.stringify(listCardTemp));
  };
  const handleCardAvartar = () => {
    const listCard = localStorage.getItem('card_checkout_avatar');
    let listCardTemp = [];
    if (listCard != null && listCard != undefined) {
      listCardTemp = JSON.parse(listCard);
      const filter = listCardTemp.filter((f) => f.product.id == products.product.id);
      if ((filter == null || filter.length == 0) && products.product.product_type != 1) {
        listCardTemp.push(products);
      }
    } else {
      if (products.product.product_type != 1) {
        listCardTemp.push(products);
      }
    }
    cartAction.updateOrderCount();
    localStorage.setItem('card_checkout_avatar', JSON.stringify(listCardTemp));
  };
  const handlecountorder=()=>{
    let count = 0
    const land = localStorage.getItem("card_checkout_land");
    const avartar = localStorage.getItem("card_checkout_avatar");
    if(land != null && land != undefined){
      
      count = count + JSON.parse(land).length
    }
    if(avartar != null && avartar != undefined){
      count = count + JSON.parse(avartar).length
    }
    setCountOrder(count);
  }
  const modalNotify = () => {
    return (
      <Fragment>
        {showModal && <div className="modal-cu">
          <div>
          <div className='row'>
            <div className='col-3'>
              <div className='flex-row align-items-center' onClick={() => setShowModal(false)}>
                <img src={images.ic_back} style={{ width: 40, height: 40 }} />{' '}
                <span style={{ fontSize: 18, fontWeight: 700, color: 'white', marginLeft: 5 }}>{t('popup.back')}</span>
              </div>
            </div>
            <div className='col-6 justify-content-center'>
              <span style={{ textAlign: 'center', fontSize: 29, fontWeight: 600, color: 'white', textTransform: 'uppercase'  }}>{products? language!="jp"?products.product.name:products.product.name_simplified_chinese:""}</span>
            </div>
            <div className='col-3 justify-content-end'>
            <img src={images.ic_cart} style={{ width: 34, height: 34 }} />
            {
              countOrd && countOrd != 0 ? <div className={classes.badge}>
              <span>{countOrd}</span>
            </div>:""
            }
            </div>
          </div>
          <div style={{ textAlign: 'center', color: 'white' }}>
            <span>
              {t('popup.price')} : <span style={{ color: '#FFD56C', fontWeight: 600 }}>{products &&<NumberFormat value={products.product.price} displayType={'text'} thousandSeparator={true} />} {title && title =="avatar"? "NetVrk": "USDT/USDC"} </span>
            </span>
          </div>
          <div className='justify-content-center'>
            <div   style={{ width: 313, height: 450,textAlign:"center" }}>
              <img width="230px" src={URL_LAND+products.product.image} alt="" />
            </div>
          </div>
          <div className='justify-content-center'>
            <Space>
              <Button size="large" disabled={true} className="ant-btn-icon add-to-cart-btn">
                <img src={ images.ic_addcart } style={{ width: 24, height: 24 }} />
                {t('popup.addcart')}
              </Button>            
              <Button size="large" disabled={true} className={!disable?"btn-c buy-now-btn":"buy-now-btn"}>
                {t('popup.buynow')}
              </Button>
            </Space>
          </div>
          <div className='justify-content-center warning-alert'>
            {exceedModal && <div>
              <img src={ images.ic_warning } style={{ width: 24, height: 24 }} />{' '}
              {t('popup.quota')}
            </div> }
          </div>
        </div>
      </div>}
      </Fragment>
    );
  };
  const localize = (item) => {
    switch (language) {
      case 'en':
        return {
          description: item.description,
          name: item.name,
        };
        break;
      case 'zht':
        return {
          description: item.description_traditional_chinese,
          name: item.name_traditional_chinese,
        };
        break;
      case 'zhs':
        return {
          description: item.description_simplified_chinese,
          name: item.name_simplified_chinese,
        };
        break;

      default:
        break;
    }
  };
  const genButtonImage = () => {
    if (products.product.name) {
      if (products.product.name == 'Common SS' || products.product.name == 'Common' || products.product.name == 'Common S') {
        return polk_images.button_common
      }
      if (products.product.name == 'Rare SS' || products.product.name == 'Rare' || products.product.name == 'Rare S') {
        return polk_images.button_rare
      }
      if (products.product.name == 'Epic SS' || products.product.name == 'Epic' || products.product.name == 'Epic S') {
        return polk_images.button_epic
      }
      if (products.product.name == 'Legendary SS' || products.product.name == 'Legendary' || products.product.name == 'Legendary S') {
        return polk_images.button_legendary
      }
      if (products.product.name == 'Mythical SS' || products.product.name == 'Mythical' || products.product.name == 'Mythical S') {
        return polk_images.button_mythical
      }

      return ""
    }    
    
  }   
  const genButtonImage_1 = () => {
    if (products.product.name) {
      if (products.product.name == 'Common SS' || products.product.name == 'Common' || products.product.name == 'Common S') {
        return polk_images.button_common_1
      }
      if (products.product.name == 'Rare SS' || products.product.name == 'Rare' || products.product.name == 'Rare S') {
        return polk_images.button_rare_1
      }
      if (products.product.name == 'Epic SS' || products.product.name == 'Epic' || products.product.name == 'Epic S') {
        return polk_images.button_epic_1
      }
      if (products.product.name == 'Legendary SS' || products.product.name == 'Legendary' || products.product.name == 'Legendary S') {
        return polk_images.button_legendary_1
      }
      if (products.product.name == 'Mythical SS' || products.product.name == 'Mythical' || products.product.name == 'Mythical S') {
        return polk_images.button_mythical_1
      }

      return ""
    }    
    
  }   

  const genButtonColorLeft = () => {
    if (products.product.name) {
      if (products.product.name == 'Common SS' || products.product.name == 'Common' || products.product.name == 'Common S') {
        return '#cccccc'
      }
      if (products.product.name == 'Rare SS' || products.product.name == 'Rare' || products.product.name == 'Rare S') {
        return '#1e7ef2'
      }
      if (products.product.name == 'Epic SS' || products.product.name == 'Epic' || products.product.name == 'Epic S') {
        return '#ae64cf'
      }
      if (products.product.name == 'Legendary SS' || products.product.name == 'Legendary' || products.product.name == 'Legendary S') {
        return '#f7c249'
      }
      if (products.product.name == 'Mythical SS' || products.product.name == 'Mythical' || products.product.name == 'Mythical S') {
        return '#d67214'
      }

      return ""
    }    
    
  } 
  const genRemainText =() => {
    if(products.remain_quantity == 0){
      return ""
    }    
    if(products.remain_quantity < 5){
      return "EXTREMELY LIMITED"
    }
    if(products.remain_quantity < 20){
      return "LIMITED"
    }
    return "AVAILABLE IN STOCK"
  }
  const handleShowModal=()=>{
   const element = document.getElementsByClassName('ant-modal-mask');
   // console.log("ádf",element)
   if(element && element.length > 0){
     debugger
    element[0].style.backgroundImage= `url(${MOCK_DATA[products.product.name]})`;
   }
    if(products && products.remain_quantity > 0){
      setShowModal(true)
    }
  }
 
  return (
    <Card className='card-item' style={{ cursor: 'pointer' }}>
      {modalNotify()}
      {/* <Row onClick={() => handleShowModal()}>
        
        
      </Row> */}
      <Col onClick={() => handleShowModal()}>
          <div className="level-top-item">
          {products? language!="jp"?products.product.name:products.product.name_simplified_chinese:""}
          </div>
          <div className='box-image'>
            <img src={URL_LAND+products.product.image} className='item' />
          </div>
          <div className="item-infor-bottom_1 d-flex flex-column" style={{ borderLeft: `12px solid ` + genButtonColorLeft() }}>
              {/* <div className={ products.remain_quantity == 0 ? 'd-flex soldout-text' : 'd-none' } ><div style={{zIndex:100,color:"#FFE6A5"}}>SOLD OUT</div></div> */}
              <div className={ `price` }>
                {/* {<NumberFormat value={products.product.price} displayType={'text'} thousandSeparator={true} />} USDT/USDC */}
                {products &&products.remain_quantity == 0? "SOLD OUT":"CLOSED" }
              </div>
              {/* <div className={ products.remain_quantity == 0 ? ' d-none' : 'inventory' }>
                 <span>{ genRemainText() }</span>
              </div>   */}
            <div className="polk-button">
              <img src={ genButtonImage_1() } />
            </div>
          </div>          


        </Col>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  badge: {
    position: 'absolute',
    top: -3,
    right:10,
    backgroundColor: '#DE350B',
    width: 18,
    height: 18,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center',
    display:'flex',
    color:'white'
  },
}));
