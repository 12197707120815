/* eslint-disable react-hooks/exhaustive-deps */
import  { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router';
import { images,polk_images } from 'assets/images';
import { useTranslation } from 'react-i18next';
import CountDown from 'components/CountDown';
import './SlideCampaign.scss';
import { checkoutService } from 'services/lootbox/checkout';
import { lbprofileAction } from 'actions/lootbox/profile';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

function SlideCampaign(props) {
  const { t, i18n } = useTranslation('common');
  const history = useHistory();
  const {type } = props;
  const [message,setMessage] = useState(null);
  const { isLoggedIn } = useSelector(({ profileLootbox }) => profileLootbox);

  useEffect(async() => {
    const res = await checkoutService.getUserLever();
    if(res && res && res.message !== "0"){
      setMessage(res.message)
    }
    
  }, []);

  const handleClickConnect = async() => {
    if(isLoggedIn){
      lbprofileAction.showModalExplore(true)
      history.push("/launchpad")
    }else{
      await lbprofileAction.connect();
       history.push("/launchpad")
       lbprofileAction.showModalExplore(true)
    }
  };
  return (
    <Box className="CampaignSlide d-flex flex-row">
      <Box className="col-6"></Box>
      <Box className="col-6 d-flex flex-column campaign-content">
        <div className="campaign-logo">
          <img src={ polk_images.logo_campaign } />
        </div>              
        <div className="campaign-welcome">
          <h2>{t('campaign.welcome')}</h2>
          <h3>{t('campaign.polkafantasy')}</h3>
        </div>              
        <div className="campaign-description">
          {t('campaign.des')}
        </div>
        <div className="col-12 campaign-countdown">
          {type =="HOME"?<Fragment>
          <div className='new-design' onClick={()=>handleClickConnect()}>{t('campaign.participate')}</div>
          </Fragment>:
          <Fragment>
            {/* {
              message && message != '0' && message != "double"?<CountDown />:""
            } */}
            <Button className="sale-end">SALE HAS ENDED</Button>
          </Fragment>
          }
          {/*<div className='new-design' onClick={()=>handleClickConnect()}>{t('campaign.participate')}</div>*/}
        </div>
      </Box>
      <Box className="roadmap"></Box>    
    </Box>
  
  );
}
export default SlideCampaign;
