/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { images, polk_images } from 'assets/images';
import './liveauction.scss';
import axios from 'axios';
import { LinkSiteLive, LinkAPILive } from 'env';
import video from '../../assets/images/polk/video_1280.mp4';
import NumberFormat from 'react-number-format';
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

function LiveAuction(props) {
  const { t, i18n } = useTranslation('common');  
  const [durationDiff, setDurationDiff] = useState(null);
  const history = useHistory();
  const [status, setStatus] = useState("BUY NOW")
  const [liveData,setLiveData] = useState(null);
  const [timeStart,setTimeStart] = useState(0)
  const [timeEnd,setTimeEnd] = useState(0)
  
  useEffect(() => {
    if(timeEnd && timeStart){
      countDownTime();
    }
  }, [timeEnd,timeStart]);
  useEffect(async()=>{
    const init = await axios.get(LinkAPILive);
    if(init && init.data.data){
      setTimeStart(init.data.data.ownerships[0].sales[0].startTime)
      setTimeEnd(init.data.data.ownerships[0].sales[0].endTime)
    }
    setInterval(async() =>{
      const res = await axios.get(LinkAPILive);
    if(res&& res.data){
      setLiveData(res.data.data)
    }
  }, 3000);
    
  },[])

  //const {data} = props

  const data = {
    end_time: moment("2021-11-09 12:00:00"),
    start_time: moment("2021-11-08 12:00:00")
  }

  function countDownTime() {
    handleClearInterval()
    const eventTime =timeEnd
    const currentTime = moment().unix();
    const start_time = timeStart
    let diffTime = start_time - currentTime;
    setStatus("COMING SOON")
    if (diffTime <= 0) {
      setStatus("BUY NOW")
      diffTime = eventTime - currentTime;
    }
    if (diffTime >= 0) {
      let ress =moment.duration(diffTime*1000, 'milliseconds')
      const intervel = setInterval(function () {
        ress =   moment.duration(ress - 1000, 'milliseconds');
        if (ress >= 0) {
          setDurationDiff(ress);
        } else {
          setDurationDiff(0);
        }
      }, 1000);
      handleSaveInterval(intervel)
    }
  }
  const handleClearInterval =()=>{
      const listInterval = localStorage.getItem("liveAuction");
    if(listInterval && listInterval != undefined && listInterval != ""){
      try {
        const objectM = JSON.parse(listInterval);
        if(objectM && objectM.length > 0){
          objectM.map(m =>{
            clearInterval(m);
          })
        }
      } catch (error) {
        localStorage.removeItem("liveAuction")
      }

    }
    localStorage.removeItem("liveAuction")
  }

  const handleSaveInterval =(idIn)=>{
    const listInterval = localStorage.getItem("liveAuction");
    const newInter = [];
    if(listInterval && listInterval != undefined && listInterval != ""){
      try {
        const objectM = JSON.parse(listInterval);
        if(objectM && objectM.length > 0){
          objectM.push(idIn)
          localStorage.setItem("liveAuction",JSON.stringify(objectM))
        }
        
      } catch (error) {
        localStorage.removeItem("liveAuction")
      }

    }else{
      newInter.push(idIn);
      localStorage.setItem("liveAuction",JSON.stringify(newInter))
    }
  }
  return (
    <div className='col-12 count-down-product d-flex flex-row'>
      <div className="col-6 auction-image">
        {/*<img src={ polk_images.liveaution_product } />*/}
        <video width="100%" loop={true} autoPlay muted controls={true} >
          <source src={video} type="video/mp4"/>
        </video>
      </div>
      <div className="col-6 auction-info d-flex flex-column">
        <div>
          <div className='title align-items-end'>{t('liveauction.title')}</div>
          <div className='col-12 currentbid d-flex flex-column align-items-start'>
            <h3>{t('liveauction.currentbid')}</h3>
            <div className="d-flex flex-row justify-content-center">
              <img src={ polk_images.usdt } /><span> { liveData&&<NumberFormat value={liveData.ownerships[0].sales[0].price} displayType={'text'} thousandSeparator={true} />} {liveData&&liveData.ownerships[0].sales[0].paymentToken.name}</span>
            </div> 
          </div>        
          { <div className='col-12 countdowntime d-flex flex-column align-items-start'>
            <h3>{status =="COMING SOON"?t('liveauction.auctionstart'):t('liveauction.auctionendin')}</h3>
            <h4 style={{color:!durationDiff || durationDiff == 0?"#FF5630":""}}> {moment.duration(durationDiff).format('DD:hh:mm:ss',{ trim: false })} </h4>
          </div>}
        </div>
        <div className="align-items-end">
          {durationDiff && durationDiff != 0 ? <div className='btn-see-more' onClick={()=> window.open(LinkSiteLive, "_blank")}>
            {t('liveauction.participate')}
          </div>:
          <div className='btn-see-more' style={{background:"#938D9F",color:"#C1C4D0"}} >
            AUCTION HAS ENDED
        </div>
          }
        </div>
      </div>
    </div>
  );
}
export default LiveAuction;
